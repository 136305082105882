import React from 'react'
import { CircularProgressbarWithChildren } from 'react-circular-progressbar'
import 'react-circular-progressbar/dist/styles.css'
import HashLoader from 'react-spinners/HashLoader'
import GlobalStateContext from '../../context/globalStateContext'
import { useAuth0 } from '../../context/react-auth0-spa'
import { modelInSeason, setDate } from '../../utils/helpers'
import modelData from './pollen-tube-growth-model.json'
import modelLogic from './_modelLogic'

function convertDatesFromServer(block) {
  let p = { ...block }
  if (block.startDate !== null) p.startDate = new Date(block.startDate)
  if (block.firstSpray !== null) p.firstSpray = new Date(block.firstSpray)
  if (block.secondSpray !== null) p.secondSpray = new Date(block.secondSpray)
  if (block.thirdSpray !== null) p.thirdSpray = new Date(block.thirdSpray)
  return p
}

export default function DashboardSummaryPTGM({ data, isLoading, station }) {
  const { user } = useAuth0()
  const { dateOfInterest: dddd } = React.useContext(GlobalStateContext)
  const dateOfInterest =
    dddd.ms > new Date(dddd.year, 5, 30, 23, 0).getTime()
      ? setDate(new Date(dddd.year, 5, 30, 23, 0))
      : dddd

  const isModelInSeason = modelInSeason(dateOfInterest, modelData)
  const [blocks, setBlocks] = React.useState([])

  let filteredBlocks = []
  if (station) {
    filteredBlocks = blocks.filter(
      (block) =>
        block.year === new Date().getFullYear() &&
        block.stationId === station.id,
    )
  }

  let hourlyData = []
  let endDate = dateOfInterest.date
  let endIdx = 1
  if (data) {
    hourlyData = data.hourlyData.filter((d) => d.temp !== 'M')
    if (dateOfInterest.isCurrentYear) {
      endIdx = hourlyData.length - 1
    } else {
      const idx = hourlyData.findIndex((d) => d.ms === endDate.getTime())
      if (idx !== -1) endIdx = idx
    }

    hourlyData = hourlyData.slice(0, endIdx + 1)
  }

  React.useEffect(() => {
    if (user && Object.keys(user.activeTools)) {
      const model = user.activeTools.find((t) => t.id === modelData.id)
      if (model) {
        const blocks = model.blocks.map((b) => convertDatesFromServer(b))
        if (blocks.length !== 0) {
          setBlocks(blocks)
        }
      }
    }
  }, [user])

  const length = blocks.length
  let gridCol = `sm:grid-cols-1`
  if (length !== 0) {
    gridCol = `sm:grid-cols-3`
  }

  if (!data) return null
  return (
    <div
      className={`grid grid-cols-1 py-4 text-gray-700 rounded ${gridCol} gap-8 bg-gray-50 px-2`}
    >
      {filteredBlocks.length === 0 && (
        <h3 className="pl-2 text-base font-extrabold">
          No blocks for current station.
        </h3>
      )}

      {filteredBlocks.length !== 0 &&
        filteredBlocks.map((block) => {
          let mData = null
          const isActive = block.startDate !== null
          let percentageOfTarget = null
          if (isActive && hourlyData.length !== 0) {
            const ciccio = modelLogic(isModelInSeason, hourlyData, block)
            const bello = ciccio.mData.findIndex(
              (d) => d.ms === dateOfInterest.ms,
            )
            mData = ciccio.mData.slice(0, bello + 1)
            if (mData && mData.length !== 0) {
              percentageOfTarget = Math.round(
                mData.slice(-1)[0].percentStyletPenetration,
              )
            }
          }
          return (
            <div key={block.id} className="bg-white border rounded-lg">
              <div className="relative z-0 px-2 py-3 space-x-3 rounded-md">
                <div className="flex items-center justify-between">
                  <p className="mr-2 text-sm font-extrabold text-gray-600 truncate">
                    {isLoading ? `...` : block.blockName}
                  </p>
                  {isActive ? (
                    <div className="flex items-center justify-center w-16 h-16">
                      <CircularProgressbarWithChildren
                        value={percentageOfTarget}
                      >
                        {percentageOfTarget === null ? (
                          <HashLoader
                            size={16}
                            color={'#1987C2'}
                            loading={isLoading}
                          />
                        ) : (
                          <span
                            className={`text-secondary-800 font-extrabold text-xs`}
                          >{`${(percentageOfTarget =
                            percentageOfTarget > 250
                              ? '250+'
                              : percentageOfTarget)}%`}</span>
                        )}
                      </CircularProgressbarWithChildren>
                    </div>
                  ) : (
                    <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-yellow-100 text-yellow-800">
                      Inactive
                    </span>
                  )}
                </div>

                <div className="space-x-1">
                  {block.firstSpray && (
                    <span
                      className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium text-gray-800 border border-gray-300 bg-white`}
                    >
                      1st Spray
                    </span>
                  )}
                  {block.secondSpray && (
                    <span
                      className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium text-gray-800 border border-gray-300 bg-white`}
                    >
                      2nd Spray
                    </span>
                  )}
                  {block.thirdSpray && (
                    <span
                      className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium text-gray-800 border border-gray-300 bg-white`}
                    >
                      3rd Spray
                    </span>
                  )}
                </div>
              </div>
            </div>
          )
        })}
    </div>
  )
}
